export default {
  translation: {
    event: {
      about: "About",
      accepted: "are accepted",
      achievedDiscount: "You have achieved {{discount}} discount",
      addDiscountCode: "Add discount code",
      addedVATToTicketPrice: "{{vat}} VAT will be added to the ticket price",
      addGiftCardCode: "Add gift card code",
      additionalProductsFees: "Additional products fees",
      addRefundProtectionToOrder: "I would like to add Refund Protection to my order, and agree to these",
      addressForInvoice: "Address for invoice",
      amount: "Amount",
      and: "&",
      apply: "Apply",
      applyNoShowFee: "No show fee only applied in case of missing participation",
      back: "Back",
      backToCheckinCounter: "Back to check-in counter",
      backToEventPage: "Back to event page",
      booth: "Booth",
      businessOrOrganisationName: "Name of business or organisation",
      buyExtras: "Buy extras",
      buyGiftCards: "Buy gift cards",
      buyTickets: "Buy tickets",
      calendarNotAvailable: "Sorry the calendar is not available at this time. Please refresh the page to try again.",
      cancel: "Cancel",
      canceledEvent: "The event has been canceled.",
      cancelledNotification: "Your notification has been cancelled",
      cancelledPayment: "The payment has been cancelled",
      cancelledRegistration: "The registration has been cancelled",
      checkout: "Checkout",
      checkSpamFolder: "Remember to check your spam folder",
      chooseOnePriority: "You can only choose one priority per housing",
      chooseOneType: "You can only choose one type per seat",
      choosePaymentMethod: "Choose payment method",
      chooseTickets: "Choose tickets",
      chooseYourSpot: "Choose your spot",
      city: "City",
      сlimateOffsetting: "Climate offsetting partnership with Climaider.",
      co2Offset: "CO2-offset your participation - {{price}} per ticket",
      co2Offsetting: "CO2 offsetting",
      comments: "Comments",
      companyOrInstitutionName: "Name of company/institution",
      confirmationSentTo: "The confirmation has been sent to",
      congratulations: "Congratulations",
      connectingToTheEventError: "There was an error connecting to the event",
      contactInformation: "Contact information",
      contactOurSupport: "Please don't hesitate to contact our support",
      continue: "Continue",
      continueRegistration: "Continue registration",
      contributionFor: "What does my contribution go towards?",
      cookieAndPrivacyPolicy: "Cookie- and privacy policy",
      counteractTheClimateEffects: "To counteract the climate effects of my participation in this event, I would like to contribute 5.00 DKK per ticket I buy.",
      countFreePassesAvailable: "You have {{count_left}} free passes available",
      createEvent: "Create event",
      customerInfo: "Customer information",
      CVRNumber: "Business reg. no.",
      dateAndTime: "Date and time",
      discountCode: "Discount code",
      discountCodeApplied: "Discount code applied",
      doubleCheckEmailSpellingAllFine: "Everything looks fine, but please double check the email spelling.",
      doubleCheckEmailSpellingSomethingWrong: "Something does not look right, please double check the email spelling.",
      downloadGiftCard: "Download gift card",
      downloadGiftCards: "Download gift cards",
      downloadSeasonTicket: "Download season ticket",
      downloadSeasonTickets: "Download season tickets",
      downloadTicket: "Download ticket",
      downloadTickets: "Download tickets",
      EANNumber: "EAN number",
      emailAboutAvailableSpots: "If more spots become available then you will receive an email.",
      emailAboutAvailableTickets: "If more tickets become available then you will receive an email.",
      enjoy: "Enjoy!",
      enjoyTheEvent: "Enjoy the event",
      enrollToWaitingList: "Enroll to Waiting List",
      errorMessage: "There was an error",
      errorOccurred: "An error occurred, please try again",
      errorTryAgain: "There was an error. Please try again.",
      eventInformation: "Event Information",
      eventIsSoldOut: "THIS EVENT IS SOLD OUT",
      eventNotFound: "Event not found",
      eventTemporarilySoldOut: "This event is temporarily sold out.",
      expired: "Expired",
      expiredEvent: "The event has expired.",
      extraInformationForEANPayment: "Extra information for EAN payment",
      extras: "Extras",
      failedFetchingQuote: "Failed fetching quote.",
      fee: "fee",
      fetchingQuote: "fetching quote",
      fewTicketsLeft: "Few tickets left",
      findOnFacebook: "Find us on Facebook",
      firstName: "First name",
      free: "Free",
      freePassesAvailable: "You have free passes available",
      fullRefundWithRefundProtection: "With Refund Protection by Booking Protect you can under certain conditions have your order refunded (in case of e.g. urgent illness or death in your immediate family). Please note documentation is required when applying for a refund.",
      gettingAdditionalProducts: "Getting additional products",
      giftCard: "Gift card",
      giftCardApplied: "Gift card applied",
      giftCardCanBeUsedForEvents: "Your gift card can now be used for events hosted by",
      giftCardCode: "Gift card code",
      giftCards: "Gift cards",
      giftCardsCanBeUsedForEvents: "Your gift cards can now be used for events hosted by",
      giftCardsWillBeSentTo: "Your gift cards will be sent to",
      giftCardWillBeSentTo: "Your gift card will be sent to",
      goToCheckinCounter: "Please go to the check-in counter.",
      goToGiftCards: "Go to gift cards",
      goToRegistration: "Go to registration",
      goToTickets: "Go to tickets",
      guest: "Guest",
      haveBeenSentTo: "{{items}} have been sent to",
      helpCenter: "Help Center",
      hereofVAT: "Hereof VAT",
      hideSummary: "Hide summary",
      houseNumber: "House no.",
      housing: "Housing",
      housingSummary: "Your Housing Summary",
      iAgreeTo: "I agree to the",
      ifHaventReceivedAnSMS: "If you haven't received an SMS, go to the check-in counter and give them your name or phone number",
      informationPerReservation: "Information per reservation",
      informationPerTicket: "Information per ticket",
      invoiceInformation: "Invoice information",
      invoiceOrEAN: "Invoice/EAN",
      lastName: "Last name",
      left: "left",
      linkToYourTickets: "with a link to your tickets",
      loadingEvents: "Loading events",
      location: "Location",
      login: "Login",
      memberCode: "Member code",
      multipleStartingTimes: "Multiple starting times",
      multipleStartTimes: "This event has multiple start times",
      newPurchase: "New Purchase",
      newRegistration: "New registration",
      news: "News",
      no: "No",
      noEvents: "No events",
      noEventsFor: "No events for",
      noShowFee: "with {{price}} no-show fee",
      notAutomaticallyTakeToPaymentWindow: "If you are not automatically taken to the payment window, please click the button below",
      noTicketsAdded: "No tickets have been added",
      notifyAboutAvailableSpots: "Do you want to be notified on email if spots for this event become available?",
      notifyAboutAvailableTickets: "Do you want to be notified on email if tickets for this event become available?",
      numberOfTickets: "Number of tickets",
      offsetAmountWillNotBeReimbursed: "The offsetting amount will not be reimbursed if tickets are later refunded.",
      openPaymentWindow: "Just a moment, we are taking you to the payment window",
      orderNumber: "Order number",
      orderSummary: "Your Order Summary",
      outOf2: "out of 2.",
      pageWillGoBack: "The page will go back to start in 10 seconds.",
      pay: "Pay",
      payByInvoiceRemark: "REMARK You have chosen to pay by invoice. You'll receive an email regarding this by the organiser",
      payForTickets: "Pay for your tickets",
      payment: "Payment",
      paymentByEANNumber: "Payment by EAN number is reserved for danish public institutions. The payment will be done by invoicing the EAN number",
      payWithCreditCard: "Pay with credit card",
      payWithMobilePay: "Pay with MobilePay",
      payWithStripe: "Pay with stripe",
      phone: "Phone",
      placeOrder: "Place order",
      postCode: "Post code",
      preparingOrder: "Preparing your order",
      priceIncludesVAT: "Price includes {{vat}} VAT",
      privacyPolicy: "Privacy Policy",
      protectOrder: "I would like to protect my order",
      provideCustomerInfo: "Please provide customer information.",
      provideRegistrationInfo: "Please provide your registration information",
      purchaseSuccessful: "The purchase was successful",
      quantity: "Quantity",
      readMoreHere: "Read more here",
      receiveNewsFrom: "Receive news from {{name}}",
      receiveNewslettersWithUpdates: "I would like to receive newsletters via e-mail with information about upcoming events, offers, and products from {{name}}. Read the privacy policy regarding the processing <5>here</5>. You always have the right to withdraw your consent.",
      receiveSMSWithTicketLink: "I would like to receive a SMS with a link directly to my ticket.",
      receiverInformation: "Receiver information",
      referenceEAN: "Reference EAN nr for EAN-number",
      refreshToReserveAgain: "Your time to checkout has expired. Please refresh to reserve again.",
      refundProtection: "Refund protection",
      refundProtectionNotAvailable: "Sorry, refund protection is not available right now.",
      register: "Register",
      registerHousesWarningMessage: "You can register for two houses, but you have only registered one. Are you sure you will proceed with only one registration?",
      registrationFor: "Registration for",
      registrationInfo: "Registration information",
      registrationNumber: "Registration number",
      registrationsOpen: "Registrations open on the",
      registrationSuccessful: "Your registration was successful",
      registrationSummary: "Your Registration Summary",
      repeat: "Repeat",
      requestErrorTryAgain: "There was an error processing your request. Please try again.",
      resendMyTicket: "Resend My Ticket",
      reservation: "reservation",
      reservationNumber: "Reservation no.",
      reservations: "reservations",
      reservationWillBeLost: "Are you sure? Your reservation will be lost.",
      reserve: "Reserve",
      row: "Row",
      save: "Save",
      seasonTicketsWillBeSentTo: "The season tickets will be sent to",
      seasonTicketWillBeSentTo: "The season ticket will be sent to",
      seat: "Seat",
      seatingCancel: "Cancel",
      seatingInformation: "Seating Information",
      seatingSelectionInvalid: "Your seating selection is invalid. Please review your selection.",
      section: "Section",
      secureSeasonTicket: "You’ve secured a season ticket to",
      secureSeasonTickets: "You’ve secured season tickets to",
      secureTicket: "You’ve secured a ticket to",
      secureTickets: "You’ve secured tickets to",
      selectAdditionalProducts: "Here you can select additional products to your ticket",
      selectDay: "Select a day",
      selectHousePriority: "Select your house priority",
      selectItemVariation: "Select variation for item",
      sendGiftCard: "Feel free to close this window - we’ll send the gift card to your email.",
      sendGiftCards: "Feel free to close this window - we’ll send the gift cards to your email.",
      sendSeasonTicket: "Feel free to close this window - we’ll send the season ticket to your email.",
      sendSeasonTickets: "Feel free to close this window - we’ll send the season tickets to your email.",
      sendTicket: "Feel free to close this window - we’ll send the ticket to your email.",
      sendTickets: "Feel free to close this window - we’ll send the tickets to your email.",
      sentMessageToFix: "Something went wrong! We've sent a messenge our tech team to fix it",
      services: "Services",
      serviceVAT: "Service VAT",
      showSeasonTicketOnSmartphone: "You don’t need to print it for the event. Just show it on your smartphone if possible",
      showSeasonTicketsOnSmartphone: "You don’t need to print them for the event. Just show them on your smartphone if possible",
      showTicketOnSmartphone: "You don’t need to print your ticket for the event. Just show it on your smartphone if possible",
      showTicketsAtCheckinCounter: "Please go to the check-in counter and show your tickets",
      showTicketsOnSmartphone: "You don’t need to print your tickets for the event. Just show them on your smartphone if possible",
      signUp: "Sign up",
      signUpToTicketNotifications: "Sign up to ticket notifications",
      SMSSentTo: "An SMS will be sent to",
      SMSTicket: "SMS-ticket",
      soldOut: "Sold out",
      startOver: "Start over",
      subscribed: "SUBSCRIBED!",
      subscribingToTicketNotifications: "Thank you for subscribing to ticket notifications",
      table: "Table",
      temporarilySoldOut: "Temporarily sold out",
      termsAndConditions: "Terms and Conditions",
      theOrganiser: "the organiser",
      ticket: "Ticket",
      ticketArea: "Ticket area",
      ticketDiscountCode: "Ticket discount code",
      ticketFees: "Ticket fees",
      ticketingPoweredBy: "Ticketing powered by",
      tickets: "Tickets",
      ticketSaleClosed: "Ticket sale is closed",
      ticketSaleEitherClosedOrNotStarted: "The ticket sale is either closed or not started yet",
      ticketSalesNotStarted: "Ticket sales haven't started yet",
      ticketSalesStart: "Ticket sales start",
      ticketSalesStarts: "The ticket sales starts on the",
      ticketsMightBecomeAvailable: "More tickets might become available, so check back within 15 minutes.",
      ticketsSentOnEmail: "Tickets are also sent on email",
      ticketsWillBeSentTo: "The tickets will be sent to",
      ticketWillBeSentTo: "The ticket will be sent to",
      timeIsUp: "Time is up",
      toCompleteOrder: "to complete your order",
      toCompleteRegistration: "to complete your registration",
      total: "Total",
      totalPrice: "Total price",
      totalReservations: "Total reservations:",
      tryAgain: "Try again",
      transactionFee: "Transaction fee",
      totalWithoutTax: "Total without tax",
      totalIncludingTax: "Total including tax",
      tax: "Tax",
      viewOrderSummary: "View order summary",
      viewRegistrationSummary: "View registration summary",
      withinAFewMinutes: "within a few minutes",
      yes: "Yes",
      youAlreadyHaveTicket: "You already have a ticket for this event. Click to continue your registration anyway",
      youHave: "You have",
      yourRegistrationWasCancelled: "Your registration was cancelled",
      phewApiNotRespondingTryLater: "Phew, our servers are really struggling to show this content right now. Don’t worry though! It will be back again soon, so try to refresh the page in 10-15 minutes.",
      selectOne: "Select one",
      noAnswerProvided: "No answer provided",
      streetLine: "Street line",
      zipPostalCode: "Zip/Postal code",
      cityState: "City, State",
      country: "Country"
    }
  }
};